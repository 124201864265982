<template>
  <div class="zui-country-phone">
    <div class="selected-flag "
         :title="`${currentData.name}: + ${currentData.dialCode}`">

      <el-input :placeholder="$t('login_page.login_phone_placeholder')" v-model="input">

        <div class="phone-left prepend" slot="prepend" @click.stop="handleShowAndFlyTo">
          <div :class="['flag', `flag-${currentData.code}`, 'cur-flag']"></div>
          <span class="area-codeNum"
                :title="`${currentData.name}: + ${currentData.dialCode}`">+{{ currentData.dialCode }}</span>
          <i class="tran"></i>
        </div>
        <template slot="append" v-if="sendSms">
            <span v-show="limitSeconds !== 0">
              {{ $t('register_page.retry_begin') }}
              {{ limitSeconds }}
              {{ $t('register_page.retry_end') }}
            </span>
          <span v-show="limitSeconds === 0" @click="sendSmsButton">
            {{ $t('login_and_register_page.verification_code') }}

          </span>
        </template>
      </el-input>

    </div>
    <transition name="fade">
      <div v-if="hideSubMenu" class="flag-list-box scroll-bar" @click.stop="hideSubMenu = true">
        <el-input v-model="countryName" type="text" class="search-input" placeholder="国家名字(The Country Name)"/>
        <ul class="country-list">
          <li v-for="(item, index) in filterCountries"
              :key="index"
              :class="['list-item', {'highlight': item.dialCode === currentCode}]"
              @click.stop="handleItemClick(item)"
          >
            <div :class="['flag', `flag-${item.code}`]"></div>
            <span class="country-name">{{ item.CNName }}-{{ item.name }}</span>
            <span class="dial-code"> +{{ item.dialCode }}</span>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
import countryListData from './countryData.json'

export default {
  name: 'CountryPhone',
  model: {
    prop: 'phoneNumber',
    event: 'phoneNumberChange'
  },
  props: {
    /*
    * @description 国际区号
    * */
    phoneCode: {
      type: Number,
      default: 86
    },
    countryCode: {
      type: String,
      default: 'cn'
    },
    sendSms: {
      type: Boolean,
      default: false,
    },
    phoneNumber: {
      type: String,
    },
    realPhoneNumber: {
      type: String,
    },
    limitSeconds: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      seconds: 0,
      phone: '',
      outPhone: '+' + this.currentCode + this.phone,
      countryName: '',
      hideSubMenu: false,
      currentCode: this.phoneCode,
      currentCountryCode: this.countryCode,
      countryList: []
    }
  },
  watch: {},
  computed: {
    input: {
      get() {
        return this.phoneNumber
      },
      set(val) {
        this.$emit('phoneNumberChange', val)
        this.$emit('listenToChildEvent', '+' + this.currentCode + val)
      },
    },
    // 根据props传过来的国际区号检索出所在的整个对象
    currentData() {
      return this.countryList.filter((item) => {
        return Object.keys(item).some((key) => {
          return item[key] === this.currentCountryCode
        })
      })[0]
    },
    // 根据输入框的内容进行模糊检索
    filterCountries() {
      if (this.countryName) {
        return this.countryList.filter((item) => {
          return Object.keys(item).some((key) => {
            return String(item[key]).toLowerCase().indexOf(this.countryName.toLowerCase()) > -1
          })
        })
      } else {
        return this.countryList
      }
    }
  },
  created() {
    // 拿到JSON里面的国家区号数据并赋值到本地data中
    this.countryList = countryListData
  },
  methods: {
    sendSmsButton(){
      this.$emit('listenSendSms', '+' + this.currentCode + this.phoneNumber)
    },
    handleShowAndFlyTo() {
      this.hideSubMenu = !this.hideSubMenu
      if (this.hideSubMenu) {
        document.addEventListener('click', () => {
          this.hideSubMenu = false
        })
        setTimeout(() => {
          document.querySelector('.highlight').scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          })
        }, 100)
      }
    },
    handleItemClick(obj) {
      this.hideSubMenu = !this.hideSubMenu;
      this.currentCode = obj.dialCode
      this.currentCountryCode = obj.code
      console.log(obj)
      // 更改父组件的值
      this.$emit('update:phoneCode', obj.dialCode)
      this.$emit('update:countryCode', obj.code)
      console.log(this.currentData)
      setTimeout(() => {

        const hight = document.querySelector('.highlight')
        hight.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        })
      }, 100)
    },
  }
}
</script>
<style scoped>
@import "./css/flags.css";

ul, li {
  padding: 0;
  margin: 0;
}

.intl-tel-input-container {
  position: relative;
  padding: 0 10px;
  font-family: "lucida grande", "lucida sans unicode", lucida, helvetica, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

.selected-flag {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  .phone-left {
    align-items: center;
    display: flex;
  }

}

.flag-list-box {
  background: #ffffff;
  text-align: left;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 42px;
  padding: 10px;
  width: 200px;
  height: 300px;
  overflow: auto;
  border: 1px solid #E5E5E5;
}

.search-input {
  width: 100%;
  height: 28px;
  background-color: transparent;
}

input::-webkit-input-placeholder {
  color: #CCCCCC;
  font-size: 12px;
}

.country-list {
  margin-top: -20px;
  list-style: none;
}

.list-item {
  margin: 10px 0;
  font-size: 12px;
  line-height: 1.5;
  color: #606266;
  cursor: pointer;
  user-select: none;
}

.list-item:hover {
  color: #303133;
}

.list-item:first-child {
  margin-top: 30px;
}

.list-item:last-child {
  margin-bottom: 0;
}

.country-name {
  margin-left: 5px;
}

.dial-code {
  /*font-weight: bold;*/
}

.area-codeNum {
  padding: 0 8px;
  display: inline-block;
  font-size: 14px;
  position: relative;
}

.tran {
  margin-top: 5px;
  margin-left: -3px;
  border-width: 5px;
  border-style: solid;
  border-color: #434343 transparent transparent;
}

.highlight {
  font-weight: bold;
  color: #303133;
}

/*滚动条样式*/
/*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
.scroll-bar::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  border-radius: 15px;
  background-color: transparent;
}

/*定义滚动条的轨道，内阴影及圆角*/
.scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #E5E5E5;
  background-color: transparent;
  border-radius: 15px;
}

/*定义滑块，内阴影及圆角*/
.scroll-bar::-webkit-scrollbar-thumb {
  height: 20px;
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px #CCCCCC;
  background-color: #888;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>